<template>
  <main id="project">
    <div class="container">
      <Step :step="1" />
      <div class="row">
        <div class="col-sm-12">
          <div class="panel">
            <div class="panel-heading">
              <h2 class="pageTitle">
                メニュー選択<span>Select menu</span>
              </h2>
            </div>
            <div class="panel-body">
              <div class="menu_list">
                <div class="menu_item">
                  <a @click="selectMenu(menu.MENU_1)">
                    任意のキャラクター<br>
                    ×<br>
                    任意のカテゴリー<br>
                    (最大入力数 5つ)
                  </a>
                </div>
                <div class="menu_item">
                  <a @click="selectMenu(menu.MENU_2)">
                    ①任意のキャラクター<br>
                    ×推奨100カテゴリー<br><br>
                    ②任意のカテゴリー<br>
                    ×推奨100キャラクター<br>
                    (最大入力数 各1つ)
                  </a>
                  <p class="help-block">
                    ※キャラクターかカテゴリーのいずれかしか選択できません
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Step from '../../components/project/Step'
import { MENU } from '../../common/constant'
import store from '../../store'
import { actions as actionsProject } from '../../store/modules/project'
import { getCategoriesApi, getCharactersApi } from '../../services/api'
import { actions as actionsApp } from '../../store/modules/app'

export default {
  name: 'SelectMenu',
  components: {
    Step
  },
  data() {
    return {
      menu: MENU
    }
  },
  async mounted() {
    await store.dispatch(actionsApp.SET_LOADING, { active: true })
    await this.getCategories()
    await this.getCharacters()
    await store.dispatch(actionsApp.SET_LOADING, { active: false })
  },
  methods: {
    async selectMenu(menu) {
      await store.dispatch(actionsProject.SET_MENU, menu)
      if (menu === this.menu.MENU_1) {
        await store.dispatch(actionsProject.RESET_DATA_RECOMMEND_INPUT)
        await this.$router.push({ name: 'CustomInput' })
      }
      if (menu === this.menu.MENU_2) {
        await store.dispatch(actionsProject.RESET_DATA_CUSTOM_INPUT)
        await this.$router.push({ name: 'RecommendInput' })
      }
      this.$destroy()
    },
    async getCategories() {
      const res = await getCategoriesApi()
      if (res.status === 200) {
        await store.dispatch(actionsProject.SET_CATEGORIES, res.data)
      }
    },
    async getCharacters() {
      const res = await getCharactersApi()
      if (res.status === 200) {
        await store.dispatch(actionsProject.SET_CHARACTERS, res.data)
      }
    }
  }
}
</script>

<style scoped>

</style>
